import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

    transform<T>(values: T[], value: any, field?: string, not?: boolean): T[] {
        if (!value) {
            return values;
        }
        if (values) {
            return values.filter(p => {
                let useField = p;
                if (field) {
                    useField = p[field];
                }
                if (not) {
                    if (typeof useField === 'function') {
                        return useField() !== value;
                    }
                    return useField !== value;
                } else {
                    if (typeof useField === 'function') {
                        return useField() === value;
                    }
                    return useField === value;
                }
            });
        }
        return null;
    }

}
