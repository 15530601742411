import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'nl2br',
    pure: false
})
export class Nl2brPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {
    }

    transform(text) {
        if (text) {
            const tex = this.escapeHtml(text);
            return this.domSanitizer.bypassSecurityTrustHtml(tex.replaceAll('\n', '<br>'));
        }
        return null;
    }


    private escapeHtml(unsafe) {
        return unsafe
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    }
}
