import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {LoggerService} from './services/logger.service';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {

    constructor(private loggerService: LoggerService,
                private zone: NgZone) {
        super();
    }

    handleError(error) {
        // Here you can provide whatever logging you want

        this.zone.run(() => {
            this.loggerService.logError(error?.stack || error?.message);
        });

        super.handleError(error);
        throw (error);
    }
}
