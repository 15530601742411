<div class="dialog-container">
    <h1 class="condensed dialog-title">Gebruikers ID</h1>
    <div class="dialog-content">
        <form [formGroup]="form">
            <mat-form-field class="w-100" id="name">
                <input formControlName="user_field"
                       matInput
                       name="user_field"
                       placeholder="Id veld"
                       type="text">
            </mat-form-field>
        </form>
    </div>
    <div class="d-flex flex-wrap">
        <button (click)="dialogRef.close()" mat-button>Annuleren</button>
        <div class="spacer"></div>
        <button (click)="save()"
                [disabled]="form.invalid || saving"
                color="primary"
                mat-raised-button>
            Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
        </button>
    </div>
</div>
